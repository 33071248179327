const isDev = process.env.NODE_ENV === 'development'

export default {
    baseUrl: {
        dev: 'https://cscyb.meilibaobiao.com:8443/cbreat/V1.0.0',
        // dev: 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0',  // https://cyb.meilibaobiao.com:8443/cbreat/V1.0.0/
        // dev: '/api',
        pro: 'https://cscyb.meilibaobiao.com:8443/cbreat/V1.0.0'
    },
    publicPath: [/^\/public/, /^\/login/],
    wsconfig: {
        url: isDev ? '127.0.0.1' : '39.100.63.95:8080/cbreat/V1.0.0',
        // url: isDev ? '127.0.0.1' : '192.168.1.183:8080/cbreat/V1.0.0',
        port: isDev ? '3001' : 22001
    },
    denglu: {
        // phone: '15106157635',
        // mima: 'ctds123'
        phone: '',
        mima: ''
    },

}