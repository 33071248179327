const state = {
    shop_type: [
        {id: '1', label: '中餐'},
        {id: '2', label: '快餐'},
        {id: '3', label: '火锅'},
        {id: '4', label: '烤肉'},
        {id: '5', label: '烧烤'},
        {id: '6', label: '宴会'}
    ]
  }
  
  const mutations = {
      
  }
  
  const actions = {
    
  }
  
  export default {
      state,
      mutations,
      actions
  }