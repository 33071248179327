import axios from '@/utils/request'
// import qs from 'qs'

// 用户签到
const userSign = () => axios.get('/user/fav')

// 更新用户基本资料
// const updateUserInfo = (data) => axios.post('/user/basic', data)

// 确认修改用户名
// const updateUsername = (data) => axios.get('/public/resetEmail?' + qs.stringify(data))

// 修改用户密码
// const changePasswd = (data) => axios.post('/user/changePassword', {
//   ...data
// })

// cbr 平台
const query_mc_pay_rate = (data) => axios.post('/query_mc_pay_rate', {
    ...data
})
/**
 * 组织结构树
 */
const query_dept_staff_tree_aim = (data) => axios.post('/query_dept_staff_tree_aim', { ...data })
/**
 * 角色列表
 */
const query_role_group_info_list = (data) => axios.post('/query_role_group_info_list', { ...data })
// 导入excel
const import_staff_ai = (data) => axios.post('/import_staff_ai', {...data })
export {
    userSign,
    query_mc_pay_rate,
    query_dept_staff_tree_aim,
    query_role_group_info_list,
    import_staff_ai
}
