import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'LoginS',
        component: () =>
            import ('@c/LoginS/index'),
        // 登陆页
    },
    {
        path: '/testceshi',
        name: 'testceshi',
        component: () =>
            import ('@/views/testceshi'),
        meta: { title: '测试' }
    },
    {
        path: '/RegisterS',
        name: 'RegisterS',
        component: () =>
            import ('@c/RegisterS/index'),
        // 注册页
    },
    {
        path: '/PasswordS',
        name: 'PasswordS',
        component: () =>
            import ('@c/PasswordS/index'),
        // 忘记密码页

    },
    {
        path: '/intimity',
        name: 'intimity',
        component: () =>
            import ('@c/intimity/index'),
        // 隐私保护政策页

    },
    {
        path: '/Approval',
        name: 'Approval',
        component: () =>
            import ('@/views/Approval'),
        meta: { title: '审批设置' }
    },
    {
        path: '/Public',
        name: 'Public',
        component: () =>
            import ('@/views/ApprovalForm/index'),
        meta: { title: '公共表单' }
    },
    {
        path: '/ApprovalFormEdit',
        name: 'ApprovalFormEdit',
        component: () =>
            import ('@/views/ApprovalForm/ApprovalFormEdit'),
        meta: { title: '公共表单新' }
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ('@/views/Home'),
        children: [
            {
                path: '/duizhang',
                name: 'duizhang',
                component: () =>import ('@c/Home/duizhang/duizhang'),
                meta: { title: '对账明细' }
            },
            {
                path: '/yingjianshezhi',
                name: 'yingjianshezhi',
                component: () =>import ('@c/Home/yingjianshezhi'),  // 
                meta: { title: '硬件设置' }
            },
            // earl 2021-09-06 新添加会员管理
            {
                path: '/manageSetting',
                name: 'managesetting',
                component: () =>import ('@c/Home/manageSetting'),  // 
                meta: { title: '会员管理' }
            },
            // earl 2021-09-07 新添加支付管理
            {
                path: '/paymentSetting',
                name: 'paymentSetting',
                component: () =>import ('@c/Home/paymentSetting'),  // 
                meta: { title: '支付管理' }
            },
            {
                path: '/Enterprise',
                name: 'Enterprise',
                component: () =>
                    import ('@c/Home/Enterprise'),
                meta: { title: '企业认证' }
            }, {
                path: '/EnterpriseXin',
                name: 'EnterpriseXin',
                component: () =>
                    import ('@c/Home/EnterpriseXin'),
                meta: { title: '企业认证编辑' }
            }, {
                path: '/EnterpriseExamine',
                name: 'EnterpriseExamine',
                component: () =>
                    import ('@c/Home/EnterpriseExamine'),
                meta: { title: '企业认证查看' }
            },
            {
                path: '/EnterpriseSubmit',
                name: 'EnterpriseSubmit',
                component: () =>
                    import ('@c/Home/EnterpriseSubmit'),
                meta: { title: '企业认证提交成功' }
            },
            {
                path: '/EnteroriseNewly',
                name: 'EnteroriseNewly',
                component: () =>
                    import ('@c/Home/EnteroriseNewly'),
                meta: { title: '企业认证我的企业' }
            },
            {
                path: '/EnterpriseConfirm',
                name: 'EnterpriseConfirm',
                component: () =>
                    import ('@c/Home/EnterpriseConfirm'),
                meta: { title: '企业认证续费' }
            },
            {
                path: '/EnterprisePayment',
                name: 'EnterprisePayment',
                component: () =>
                    import ('@c/Home/EnterprisePayment'),
                meta: { title: '企业认证支付' }
            },
            {
                path: '/EnterpriseComplete',
                name: 'EnterpriseComplete',
                component: () =>
                    import ('@c/Home/EnterpriseComplete'),
                meta: { title: '企业认证支付完成' }
            },
            {
                path: '/Admin',
                name: 'Admin',
                component: () =>
                    import ('@c/Home/Admin'),
                meta: { title: '主管理员' }
            },

            {
                path: '/AdminAccount',
                name: 'AdminAccount',
                component: () =>
                    import ('@c/Home/AdminAccount'),
                meta: { title: '主管理员账号' }
            },
            {
                path: '/AdminAmend',
                name: 'AdminAmend',
                component: () =>
                    import ('@c/Home/AdminAmend'),
                meta: { title: '主管理员修改密码' }
            },
            {
                path: '/AdminForget',
                name: 'AdminForget',
                component: () =>
                    import ('@c/Home/AdminForget'),
                meta: { title: '主管理员忘记密码' }
            },
            {
                path: '/AdminS',
                name: 'AdminS',
                component: () =>
                    import ('@c/Home/AdminS'),
                meta: { title: '子管理员' }
            },
            {
                path: '/Nodif',
                name: 'Nodif',
                component: () =>
                    import ('@c/Home/nodif'),
                meta: { title: '暂无内容' }
            },
            {
                path: '/EditorialEnterprise',
                name: 'EditorialEnterprise',
                component: () =>
                    import ('@c/Home/EditorialEnterprise'),
                meta: { title: '编辑企业' }
            },
            {
                path: '/Centrepot',
                name: 'Centrepot',
                component: () =>
                    import ('@c/Home/Centrepot'),
                meta: { title: '仓库管理' }
            },
            {
                path: '/Mubiao',
                name: 'Mubiao',
                component: () =>
                    import ('@/views/mubiao'),
                meta: { title: '目标' }
            },
            {
                path: '/ApprovalList',
                name: 'ApprovalList',
                component: () =>
                    import ('@/views/ApprovalList'),
                meta: { title: '审批设置' }
            },

            // {
            //     path: '/Cloud',
            //     name: 'Cloud',
            //     component: () =>
            //         import ('@c/Home/Cloud'),
            //     meta: { title: '云盘设置' },
            //     children: [{
            //             path: '/CloudDiskSettings',
            //             name: 'CloudDiskSettings',
            //             component: () =>
            //                 import ('@c/Home/CloudDiskSettings'),
            //             meta: { title: '云盘' }
            //         },
            //         {
            //             path: '/cloudStorage',
            //             name: 'cloudStorage',
            //             component: () =>
            //                 import ('@c/Home/cloudStorage'),
            //             meta: { title: '容量管理' }
            //         },
            //         {
            //             path: '/ClickExpand',
            //             name: 'ClickExpand',
            //             component: () =>
            //                 import ('@c/Home/ClickExpand'),
            //             meta: { title: '点击扩容' }
            //         }
            //     ]
            // },


            {
                path: '/Settings',
                name: 'Settings',
                component: () =>
                    import ('@c/Home/Settings'),
                meta: { title: '功能权限设置' }
            },
            {
                path: '/supplier',
                name: 'supplier',
                component: () =>
                    import ('@c/Home/supplier'),
                meta: { title: '供应商模板' }
            },
            {
                path: '/PeiXun',
                name: 'PeiXun',
                component: () =>
                    import ('@c/Home/PeiXun'),
                meta: { title: '培训管理' }
            },

            {
                path: '/Derive',
                name: 'Derive',
                component: () =>
                    import ('@c/Home/Derive'),
                meta: { title: '审批设置数据导出' }
            },
            {
                path: '/DeskManagement',
                name: 'DeskManagement',
                component: () =>
                    import ('@c/Home/DeskManagement'),
                meta: { title: '桌台管理' }
            },
            {
                path: '/zhuotaiquyu',
                name: 'zhuotaiquyu',
                component: () =>
                    import ('@c/Home/zhuotaiquyu'),
                meta: { title: '区域管理' }
            },
            {
                path: '/DishManagement',
                name: 'DishManagement',
                component: () =>
                    import ('@c/Home/DishManagement'),
                meta: { title: '菜品管理全部菜品' }
            },
            {
                path: '/SwitchPage',
                name: 'SwitchPage',
                component: () =>
                    import ('@/views/SwitchSuccessfully'),
                meta: { title: '切换企业' }
            },
            {
                path: '/AttributeManagement',
                name: 'AttributeManagement',
                component: () =>
                    import ('@c/Home/AttributeManagement'),
                meta: { title: '归口管理' }
            },
            {
                path: '/Classification',
                name: 'Classification',
                component: () =>
                    import ('@c/Home/Classification'),
                meta: { title: '菜品管理菜品分类' }
            },
            {
                path: '/AnalyseDataSet',
                name: 'AnalyseDataSet',
                component: () =>
                    import ('@c/Home/AnalyseDataSet')
            },
            {
                path: '/MarketingCampaign',
                name: 'MarketingCampaign',
                component: () =>
                    import ('@c/Home/MarketingCampaign')
            },


            {
                path: '/ceShi',
                name: 'ceShi',
                component: () =>
                    import ('@c/Home/ceShi'),
                meta: { title: '测试' },
                linkActiveClass: 'current_ele',
                children: [
                    //门店信息
                    {
                        path: '/InfoMsettings',
                        name: 'InfoMsettings',
                        component: () =>
                            import ('@c/ToggleSetting/InfoMsettings')
                    },

                    //节点管理
                    {
                        path: '/NodeManagement',
                        name: 'NodeManagement',
                        component: () =>
                            import ('@c/ToggleSetting/NodeManagement')
                    },

                    {
                        path: '/journal',
                        name: 'journal',
                        component: () =>
                            import ('@c/Home/journal'),
                        meta: { title: '管理员操作日志' }
                    },
                    {
                        path: '/Inquire',
                        name: 'Inquire',
                        component: () =>
                            import ('@c/Home/Inquire'),
                        meta: { title: '查询管理导入查询' }
                    },
                    // 云盘管理
                    {
                        path: '/Cloud',
                        name: 'Cloud',
                        component: () =>
                            import ('@c/Home/Cloud'),
                        meta: { title: '云盘设置' },
                        children: [{
                                path: '/CloudDiskSettings',
                                name: 'CloudDiskSettings',
                                component: () =>
                                    import ('@c/ToggleSetting/CloudDiskSettings'),
                                meta: { title: '云盘' }
                            },
                            {
                                path: '/cloudStorage',
                                name: 'cloudStorage',
                                component: () =>
                                    import ('@c/ToggleSetting/cloudStorage'),
                                meta: { title: '容量管理' }
                            }, {
                                path: '/CloudDiskDetails',
                                name: 'CloudDiskDetails',
                                component: () =>
                                    import ('@c/ToggleSetting/CloudDiskDetails'),
                            },
                            {
                                path: '/ClickExpand',
                                name: 'ClickExpand',
                                component: () =>
                                    import ('@c/ToggleSetting/ClickExpand'),
                                meta: { title: '点击扩容' }  
                            },
                            {
                                path: '/Clickyunpanfuuan',
                                name: 'Clickyunpanfuuan',
                                component: () =>
                                    import ('@c/ToggleSetting/Clickyunpanfuuan'),
                                meta: { title: '扩容付款' }  
                            },
                            {
                                path: '/Clickyunok',
                                name: 'Clickyunok',
                                component: () =>
                                    import ('@c/ToggleSetting/Clickyunok'),
                                meta: { title: '付款完成' }  
                            },


                        ]
                    },
                    {
                        path: '/shuoming',
                        name: 'shuoming',
                        component: () =>
                            import ('@c/ToggleSetting/shuoming'),
                        meta: { title: '云盘说明' }
                    },

                    // {
                    //     path: '/wenjiangl',
                    //     name: 'wenjiangl',
                    //     component: () =>
                    //         import ('@c/ToggleSetting/wenjiangl'),
                    //     meta: { title: '文件管理' }
                    // },

                    // {
                    //     path: '/MarketingCampaign',
                    //     name: 'MarketingCampaign',
                    //     component: () =>
                    //         import ('@c/ToggleSetting/MarketingCampaign')
                    // },
                    // {
                    //     path: '/BusinessAccounting',
                    //     name: 'BusinessAccounting',
                    //     component: () =>
                    //         import ('@c/ToggleSetting/BusinessAccounting')
                    // },

                    // {
                    //     path: '/AnalyzeData',
                    //     name: 'AnalyzeData',
                    //     component: () =>
                    //         import ('@c/ToggleSetting/AnalyzeData')
                    // },
                    // {
                    //     path: '/WeekdaySetting',
                    //     name: 'WeekdaySetting',
                    //     component: () =>
                    //         import ('@c/ToggleSetting/WeekdaySetting')
                    // },
                    // {
                    //     path: '/TheDataSet',
                    //     name: 'TheDataSet',
                    //     component: () =>
                    //         import ('@c/ToggleSetting/TheDataSet')
                    // }
                ]
            },
            {
                path: '/wenjiangl',
                name: 'wenjiangl',
                component: () =>
                    import ('@c/ToggleSetting/wenjiangl'),
                meta: { title: '文件管理' }
            },
            {
                path: '/renshi',
                name: 'renshi',
                component: () =>
                    import ('@c/Home/renshi'),
                meta: { title: '人事' },
                linkActiveClass: 'current_ele',
                children: [
                    //门店信息

                    {
                        path: '/PeopleManage',
                        name: 'PeopleManage',
                        component: () =>
                            import ('@c/ToggleSetting/PeopleManage')
                    },
                    {
                        path: '/CharacterSet',
                        name: 'CharacterSet',
                        component: () =>
                            import ('@c/ToggleSetting/CharacterSet')
                    },
                    {
                        path: '/workday',
                        name: 'workday',
                        component: () =>
                            import ('@c/ToggleSetting/workday')

                    },
                    {
                        path: '/workday2',
                        name: 'workday2',
                        component: () =>
                            import ('@c/ToggleSetting/workday2')

                    }

                ]
            },
            {
                path: '/workdayShop',
                name: 'workdayShop',
                component: () =>
                    import ('@c/Home/workdayShop'),
                meta: { title: '工作日设置' }
            },
            {
                path: '/workday',
                name: 'workday',
                component: () =>
                    import ('@c/Home/workday'),
                meta: { title: '工作日设置明细' }
            },

            {
                path: '/funauth',
                name: 'funauth',
                component: () =>
                    import ('@c/Home/funauth'),
                meta: { title: '功能' },
                linkActiveClass: 'current_ele',
                children: [{
                        path: '/FullModule',
                        name: 'FullModule',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/Target',
                        name: 'Target',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/Order',
                        name: 'Order',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/Cooking',
                        name: 'Cooking',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/MealPreparation',
                        name: 'MealPreparation',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/Clear',
                        name: 'Clear',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/StorageRoom',
                        name: 'StorageRoom',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/Purchase',
                        name: 'Purchase',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/ResearchDevelopment',
                        name: 'ResearchDevelopment',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/QualityTesting',
                        name: 'QualityTesting',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/HumanResources',
                        name: 'HumanResources',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/Finance',
                        name: 'Finance',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    },
                    {
                        path: '/Administration',
                        name: 'Administration',
                        component: () =>
                            import ('@c/Home/modular/Function')
                    }
                ]
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router