import axios from '@/utils/request'
// 修改门店
const update_shop_info = (data) => axios.post('/update_shop_info', {...data })

const query_ent_detail = (data) => axios.post('/query_ent_detail', {...data })


    // 查询门店列表
const query_shop_info_list = (data) => axios.post('/query_shop_info_list', {...data })
    // 新增门店
const insert_shop_info = (data) => axios.post('/insert_shop_info', {...data })
    // 删除门店
const delete_shop_info = (data) => axios.post('/delete_shop_info', {...data })
    // 组织架构
const query_dept_staff_tree_aim_not = (data) => axios.post('/query_dept_staff_tree_aim_not', {...data })
    // 查询员工节点列表
const query_staff_node = (data) => axios.post('/query_staff_node', {...data })
    // 保存节点
const update_staff_node = (data) => axios.post('/update_staff_node', {...data })
    // 查询企业节点数
const query_ent_node_num = (data) => axios.post('/query_ent_node_num', {...data })
    // 新增采购规则
const insert_rule_purchase = (data) => axios.post('/insert_rule_purchase', {...data })
    // 新增库存规则
const insert_rule_stock = (data) => axios.post('/insert_rule_stock', {...data })
    // 新增仓库
const insert_rule_stock_qty = (data) => axios.post('/insert_rule_stock_qty', {...data })

const query_store_house_list = (data) => axios.post('/query_store_house_list', {...data })
    // 查询仓库列表
const query_prod_info_list = (data) => axios.post('/query_prod_info_list', {...data })
    // 仓库产品
const query_rule_stock_qty_list = (data) => axios.post('/query_rule_stock_qty_list', {...data })

const query_ent_site_tree = (data) => axios.post('/query_ent_site_tree', {...data })

const query_rule_purchase_detail = (data) => axios.post('/query_rule_purchase_detail', {...data })

const query_rule_stock_detail = (data) => axios.post('/query_rule_stock_detail', {...data })

const query_prod_tree_sale_rule = (data) => axios.post('/query_prod_tree_sale_rule', {...data })
    // 新增图片
const replace_ent_img = (data) => axios.post('/replace_ent_img', {...data })
    // 查询图片   /
const query_ent_img_list = (data) => axios.post('/query_ent_img_list', {...data })
    // 查询产品列表
    // 设置会员规则
const insert_member_rule = (data) => axios.post('/insert_member_rule', {...data })

const query_member_rule = (data) => axios.post('/query_member_rule', {...data })

const query_belong_to_rule = (data) => axios.post('/query_belong_to_rule', {...data })

const query_user_ent_list = (data) => axios.post('/query_user_ent_list', {...data })
const query_shop_status_list_of_in_charge = (data) => axios.post('/query_shop_status_list_of_in_charge',{ ...data })

const query_user_aut_ent_list = (data) => axios.post('/query_user_aut_ent_list', {...data })
const query_dept_staff_tree_aim = (data) => axios.post('/query_dept_staff_tree_aim_not', {
        ...data
    })
    /**
     * 归口管理保存
     * @param {ent_id,shop_id:门店id,
     * belong_type:1专人负责，2归口负责人设置,
     * list_special:[market_type:开市类型：1早市，2午市,3晚市,staff_id：负责人],
     * list_bt:[market_type:开市类型：1早市,2午市,3晚市,staff_id:负责人,table_id:桌台id]]} data
     */
const insert_belong_to_rule = (data) => axios.post('/insert_belong_to_rule', {
    ...data
})

const insert_role_group_info = (data) => axios.post('/insert_role_group_info', {
    ...data
})

const query_role_group_info_list = (data) => axios.post('/query_role_group_info_list', {
    ...data
})

const delete_role_info = (data) => axios.post('/delete_role_info', {...data })
const delete_role_group_info = (data) => axios.post('/delete_role_group_info', {
    ...data
})

const insert_role_info = (data) => axios.post('/insert_role_info', {
    ...data
})

const set_staff_role_rel = (data) => axios.post('/set_staff_role_rel', {
    ...data
})

const query_user_list_of_role = (data) => axios.post('/query_user_list_of_role', {
        ...data
    })
    // 新增目标规则
const insert_as_shop_prod_rule = (data) => axios.post('/insert_as_shop_prod_rule', {...data })
    // 查询目标规则 ent_id shop_id
const query_as_shop_prod_list = (data) => axios.post('/query_as_shop_prod_list', {...data })

const query_cat_prod_list_of_tree = (data) => axios.post('/query_cat_prod_list_of_tree', {...data })
    // 获取桌台管理状态
const query_shop_status_list_of_bt = (data) => axios.post('/query_shop_status_list_of_bt', {...data })
const query_shop_type_tree = (data) => axios.post('/query_shop_type_tree', {...data })
const query_ent_img_shop_list = (data) => axios.post('/query_ent_img_shop_list', {...data })

//新增预约负责人员规则
/*
    ent_id shop_id list_special
*/
const insert_book_staff_in_charge = (data) => axios.post('/insert_book_staff_in_charge', {...data })

/*
    查询预约人员列表
    ent_id shop_id market_type //非必填
 */
const query_book_staff_in_charge_list = (data) => axios.post('/query_book_staff_in_charge_list', {...data })
    //获取营销图默认展示
    // const query_ent_img_list = (data) => axios.post('/query_ent_img_list',{...data})


/**
 * 支付设置的
 */
// 新增支付方式
 const insert_set_pay_type = (data) => axios.post('/insert_set_pay_type', {...data })

//  删除支付方式
const delete_set_pay_type = (data) => axios.post('/delete_set_pay_type', {...data })
// 保存设置的支付方式
const save_set_pay_type = (data) => axios.post('/save_set_pay_type', {...data })
// 查询支付方式设置
const query_set_pay_type_list = (data) => axios.post('/query_set_pay_type_list', {...data })
// 查询已设置支付方式的门店
const query_set_pay_type_shop_list = (data) => axios.post('/query_set_pay_type_shop_list', {...data })


// 设置打印规则
const insert_print_rule = (data) => axios.post('/insert_print_rule', {...data })
// 查询打印规则
const query_print_rule = (data) => axios.post('/query_print_rule', {...data })
// 查询门店的打印机列表
const query_shop_print_list = (data) => axios.post('/query_shop_print_list', {...data })
// 打印设置的门店
const query_has_set_print_shop_list = (data) => axios.post('/query_has_set_print_shop_list', {...data })

// query_check_no_list  盘点id
const query_check_no_list =  (data) => axios.post('/query_check_no_list', {...data })

// 下载供应商报价单的模板
const export_supply_quotation_template = (data) => axios.post('/export_supply_quotation_template', {...data })


// --- --- --- ---
// 查询文件夹列表
const query_user_folder_list = ( data ) => axios.post('/query_user_folder_list', {...data })
// 新建文件夹
const insert_folder = ( data ) => axios.post('/insert_folder', {...data })
// 更新文件夹
const update_folder = ( data ) => axios.post('/update_folder', {...data })
// 删除文件夹
const delete_folder = ( data ) => axios.post('/delete_folder', {...data })
// 上传文件
const oss_upload_file = ( data ) => axios.post('/oss_upload_file', {...data })
// 删除文件
const oss_delete_file = ( data ) => axios.post('/oss_delete_file', {...data })
// 删除多个文件
const oss_delete_files = ( data ) => axios.post('/oss_delete_files', {...data })

// 移动的文件夹
const move_file = ( data ) => axios.post('/move_file', {...data })

// 设置网盘的权限
const set_net_disk_auth = ( data ) => axios.post('/set_net_disk_auth', {...data })
// 查询用户的文件权限列表
const query_user_folder_auth_list = ( data ) => axios.post('/query_user_folder_auth_list', {...data })


// 饼图 数据
const query_ent_net_disk_info = ( data ) => axios.post('/query_ent_net_disk_info', {...data })


// 云盘 付款  判断是不是第一次购买云盘
const if_first_buy_disk = ( data ) => axios.post('/if_first_buy_disk', {...data })

// 计算费用
const cal_buy_net_disk_fee = ( data ) => axios.post('/cal_buy_net_disk_fee', {...data })

// 新增网盘购买订单
const insert_order_net_disk = ( data ) => axios.post('/insert_order_net_disk', {...data })


// 更新企业系统的设置
const update_ent_sys_set = ( data ) => axios.post('/update_ent_sys_set', {...data })
// 查询企业系统设置
const query_ent_sys_set = ( data ) => axios.post('/query_ent_sys_set', {...data })


/**
 * earl 新增
 */
const insert_shop_intro=(data)=>axios.post('insert_shop_intro', {...data })
const query_shop_intro=(data)=>axios.post('query_shop_intro', {...data })
const insert_member_rule_ming=(data)=>axios.post('insert_member_rule_ming', {...data })
const query_member_rule_ming=(data)=>axios.post('query_member_rule_ming', {...data})
const query_member_file_list=(data)=>axios.post('query_member_file_list', {...data})
const query_cash_acct_list=(data)=>axios.post('query_cash_acct_list', {...data})
const insert_cash_acct=(data)=>axios.post('insert_cash_acct', {...data})
const update_cash_acct=(data)=>axios.post('update_cash_acct', {...data})
const delete_cash_acct=(data)=>axios.post('delete_cash_acct', {...data})
const insert_cash_set=(data)=>axios.post('insert_cash_set', {...data})
const query_cash_set_list=(data)=>axios.post('query_cash_set_list', {...data})
const query_group_buy_retail_list=(data)=>axios.post('query_group_buy_retail_list', {...data})
const query_acct_income_list=(data)=>axios.post('query_acct_income_list', {...data})
const query_acct_expend_list=(data)=>axios.post('query_acct_expend_list', {...data})
const query_acct_inv_list=(data)=>axios.post('query_acct_inv_list', {...data})

const query_acct_amt_detail=(data)=>axios.post('query_acct_amt_detail', {...data})
const query_rule_point_hp=(data)=>axios.post('query_rule_point_hp', {...data})
const insert_point_rule_hp=(data)=>axios.post('insert_point_rule_hp', {...data})
const insert_action_set_hp=(data)=>axios.post('insert_action_set_hp', {...data})
const query_action_set_hp=(data)=>axios.post('query_action_set_hp', {...data})
const insert_retail_set=(data)=>axios.post('insert_retail_set', {...data})
const query_retail_set=(data)=>axios.post('query_retail_set', {...data})
const query_shop_info_detail=(data)=>axios.post('query_shop_info_detail', {...data})
const insert_lottery_limit=(data)=>axios.post('insert_lottery_limit', {...data})
const query_lottery_limit=(data)=>axios.post('query_lottery_limit', {...data})
const query_inv_info_list_fy=(data)=>axios.post('query_inv_info_list_fy', {...data}) // 发票列表
const insert_inv_info=(data)=>axios.post('insert_inv_info', {...data}) // 新增发票
const query_inv_info_amt_detail=(data)=>axios.post('query_inv_info_amt_detail', {...data}) // 发票head详情
const query_prod_price_uniform = (data) =>axios.post('query_prod_price_uniform', {...data}) // 发票head详情
const insert_inv_set = (data) =>axios.post('insert_inv_set', {...data}) // 新增发票设置方案
const query_shop_inv_set = (data) =>axios.post('query_shop_inv_set', {...data}) // 查询门店的开票方案设置



export {
    query_shop_type_tree,
    query_cat_prod_list_of_tree,
    query_member_rule,
    delete_role_info,
    query_user_list_of_role,
    insert_role_info,
    set_staff_role_rel,
    delete_role_group_info,
    query_role_group_info_list,
    insert_role_group_info,
    insert_belong_to_rule,
    query_user_ent_list,
    query_dept_staff_tree_aim,
    query_user_aut_ent_list,
    query_belong_to_rule,
    insert_member_rule,
    replace_ent_img,
    query_ent_img_list,
    query_rule_stock_detail,
    query_prod_tree_sale_rule,
    query_prod_info_list,
    query_rule_purchase_detail,
    query_ent_site_tree,
    query_rule_stock_qty_list,
    insert_rule_stock_qty,
    query_store_house_list,
    insert_rule_stock,
    insert_rule_purchase,
    query_ent_node_num,
    query_staff_node,
    update_staff_node,
    insert_shop_info,
    query_dept_staff_tree_aim_not,
    delete_shop_info,
    update_shop_info,
    query_shop_info_list,
    insert_as_shop_prod_rule,
    query_as_shop_prod_list,
    query_shop_status_list_of_bt,
    query_ent_img_shop_list,
    insert_book_staff_in_charge,
    query_book_staff_in_charge_list,
    query_shop_status_list_of_in_charge,

    insert_set_pay_type,
    delete_set_pay_type,
    save_set_pay_type,
    query_set_pay_type_list,
    query_set_pay_type_shop_list,

    insert_print_rule,
    query_print_rule,
    query_shop_print_list,
    query_has_set_print_shop_list,
    query_check_no_list, // 盘点list
    export_supply_quotation_template,

    query_user_folder_list,
    insert_folder,
    update_folder,
    delete_folder,
    oss_upload_file,
    oss_delete_file,
    oss_delete_files,
    move_file,

    set_net_disk_auth,
    query_user_folder_auth_list,
    query_ent_net_disk_info,

    if_first_buy_disk,
    cal_buy_net_disk_fee,
    insert_order_net_disk,

    update_ent_sys_set,
    query_ent_sys_set,
    query_ent_detail,
    // earl add
    insert_shop_intro,
    query_shop_intro,
    insert_member_rule_ming,
    query_member_rule_ming,
    query_member_file_list,
    query_cash_acct_list,
    insert_cash_acct,
    update_cash_acct,
    delete_cash_acct,
    insert_cash_set,
    query_cash_set_list,
    query_group_buy_retail_list,
    query_acct_income_list,
    query_acct_expend_list,
    query_acct_inv_list,
    query_acct_amt_detail,
    query_rule_point_hp,
    insert_point_rule_hp,
    insert_action_set_hp,
    query_action_set_hp,
    insert_retail_set,
    query_retail_set,
    query_shop_info_detail,
    insert_lottery_limit,
    query_lottery_limit,
    insert_inv_info,
    query_inv_info_list_fy,
    query_inv_info_amt_detail,
    query_prod_price_uniform,
    insert_inv_set,
    query_shop_inv_set
}