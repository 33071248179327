import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@css/style.scss'
import bus from '@/utils/bus'
import Alert from '@c/modules/alert'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@css/element-variables.scss'
import '@/plugins/UI'
import '@/plugins/Approval'
import moment from 'moment'

import VueAMap from 'vue-amap';
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
    key: '78be1c08d239fa14d2387211cfff817c',
    plugin: [ // 插件集合 （插件按需引入）
        "AMap.Autocomplete", //输入提示插件
        "AMap.PlaceSearch", //POI搜索插件
        "AMap.Scale", //右下角缩略图插件 比例尺
        "AMap.OverView", //地图鹰眼插件
        "AMap.ToolBar", //地图工具条
        "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
        "AMap.PolyEditor", //编辑 折线多，边形
        "AMap.CircleEditor", //圆形编辑器插件
        "AMap.Geolocation" //定位控件，用来获取和展示用户主机所在的经纬度位置
    ]
});

// import AmapVue from '@amap/amap-vue';

// AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
// AmapVue.config.key = '4e999690ea846c355ddcdef8d72eeb52';
// AmapVue.config.plugins = [
//     'AMap.ToolBar',
//     'AMap.MoveAnimation',
//     // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
// ];
// Vue.use(AmapVue);



// import AmapVue from '@amap/amap-vue'

// AmapVue.config.key = '4e999690ea846c355ddcdef8d72eeb52'
// Vue.use(AmapVue)

Vue.prototype.$moment = moment
Vue.prototype.$bus = bus
Vue.use(Alert)
Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')