import Vue from 'vue'
import Vuex from 'vuex'
import PublicInfo from './info'
import getters from './getters'
import app from './modules/app'

Vue.use(Vuex)

let staff_info = {}
let userInfo = {}
let ent_id = ''
let user_id = ""
let phone_pc = ''
let jurisdiction = '' // 权限
let ent_info = {}
    // console.log('-----------------------------------',JSON.parse(sessionStorage.getItem('staff_info')));

if (sessionStorage.getItem('staff_info')) {
    if (JSON.parse(sessionStorage.getItem('staff_info'))) {
        // console.log("JSON.parse(sessionStorage.getItem('staff_info')",JSON.parse(sessionStorage.getItem('staff_info')) );
        staff_info = JSON.parse(sessionStorage.getItem('staff_info'))
    }
}

if (sessionStorage.getItem('user_id')) {
    if (sessionStorage.getItem('user_id')) {
        user_id = sessionStorage.getItem('user_id')
    }
}

// if (sessionStorage.getItem('ent_id')) {
  if (sessionStorage.getItem('ent_id')) {
      console.log('-----------------------------------------');
      ent_id = sessionStorage.getItem('ent_id')
  }
// }

// if (this.$store.state.ent_id) {

//     if (this.$store.state.ent_id) {


//         ent_id = this.$store.state.ent_id
//     }
// }
if (sessionStorage.getItem('phone_pc')) {

    if (sessionStorage.getItem('phone_pc')) {


        phone_pc = sessionStorage.getItem('phone_pc')
    }
}

if (sessionStorage.getItem('jurisdiction')) {
  jurisdiction = JSON.parse(sessionStorage.getItem('jurisdiction')) 
}

if (sessionStorage.getItem('ent_info')) {
    ent_info = sessionStorage.getItem('ent_info')
}


export default new Vuex.Store({
    state: {
        urlarr: [],
        loading: false,
        user_id: user_id,
        phone_pc: phone_pc,
        ent_id: ent_id,
        userInfo: userInfo,
        staff_info: staff_info,
        qyInfo: {},
        staff_list: [],
        shop_list: [],
        cat_list: [],
        ent_info: ent_info, //{},
        rndid: ['B8Jfk92K1k', 'Cs92kf84hc', 'Wnfjs8h52c', 'Qh82bf00kj', 'R13f2fKi3k', 'Cfj2ig8D0k', 'C1e34f32rg', 'Fw1fF2fr5p', 'F24W1Qry5k', 'H2hgk94Hy'],
        jurisdiction:jurisdiction, // 权限
        foodsList: [],
        foodsShopList: []
    },
    mutations: {
        SET_FOODS_LIST: (state, list)=>{
            state.foodsList = list
        },
        SET_SHOP_FOODS_LIST: (state, list)=>{
            state.foodsShopList = list
        }
    },
    actions: {
        setFoodsList({commit}, params) {
            PublicInfo.getShopFoodsList(params).then(res => {
                if(res && res.code == 200) {
                    res.body.data.map(item=>{
                        item['e_show_label'] = item.cat_name
                        item['e_show_id'] = item.id
                        item.prod_children.map(child=>{
                            child['e_show_label'] = child.prod_name
                            child['e_show_id'] = child.prod_id
                        })
                    })
                    if(params.shop_id) commit('SET_SHOP_FOODS_LIST', res.body.data)
                    else commit('SET_FOODS_LIST', res.body.data)
                }
            })
        },
        get_shop_list(state) {
            const { ent_id } = state.state
            PublicInfo.get_shop_list(ent_id).then(res => {
                console.log('============');
                state.state.shop_list = res
            })
        },
        get_staff_list(state) {
            const { ent_id } = state.state
            PublicInfo.query_ent_staff_list(ent_id).then(res => {
                state.state.staff_list = res
            })
        },
        get_cat_list(state) {
            const { ent_id } = state.state
            return new Promise((resolve) => {
                PublicInfo.query_prod_tree_of_1cat_2prod(ent_id).then(res => {
                    state.state.cat_list = res
                    resolve(res)
                })
            })
        }
    },
    modules: {
        app
    },
    getters
})