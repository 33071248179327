<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
    v-if="dialogVisible"
    :before-close="handleClose"
  >
    <div class="grayBLock">
      <div class="choose-box">
        <div class="choose-box-title" v-if="jiaose&& (typeof jiaose!=='string') ">
          <span
              :class="{ active: Isshow}"
              @click="Isshow = true;listdata = []"
            >组织结构</span
          >
          <span
              :class="{ active: !Isshow }"
              @click="Isshow = false;listdata = []"
            >角色列表</span
          >
        </div>
        <el-scrollbar v-show="Isshow === '1'">
          <el-tree
            style="background: none"
            :data="treeData1"
            show-checkbox
            ref="eltree"
            highlight-current
            node-key="id"
            :default-expanded-keys="expandedData"
            :default-checked-keys="checkedData"
            :props="defaultProps"
            @check="hankld"
            @check-change="hankldchange"
          >
          </el-tree>
        </el-scrollbar>
        <el-scrollbar v-if ="Isshow === '2'" >
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item
              v-for="(item, index) in jiaoseDatas"
              :name="index"
              :title="item.role_group_name"
              :key="index">

                <li v-for="(items,index) in item.role_list" :key="index" class="choose-box-li">
                  <label>
                        <input
                            type="checkbox"
                            id="checkboxclass"
                            @click="checkbox(items,index)"
                          />
                          <span id="checkboxclass-span" :class="{checked:listdata.includes(items)}"></span>
                          <p>{{items.role_name}}</p>
                  </label>
                </li>
            </el-collapse-item>
          </el-collapse>
        </el-scrollbar>
        <el-scrollbar v-if="Isshow === '3'">

       <li v-for="(items,index) in treedata" :key="index" class="choose-box-li" style="padding-left:10px;">
                  <label>
                        <input
                            type="checkbox"
                            id="checkboxclass"
                            @click="checkbox(items,index)"
                          />
                          <span id="checkboxclass-span" :class="{checked:listdata.includes(items)}"></span>
                          <p>{{items.staff_name}}</p>
                  </label>
                </li>
        </el-scrollbar>

      </div>
      <div class="right">
        <el-scrollbar>
          <ul
            v-for="(item, index) in listdata"
            class="right-ul"
            :key="index"
            :class="{ active: index % 2 === 0 }"
          >
            <li>
              <div>{{ item.label | first }}</div>
              <p>{{ item.label }}</p>
              <c-icon name="guanbi" @click.native="del(index, item)"></c-icon>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="changeDialog()">取 消</el-button>
      <el-button type="primary" @click="changeDialog()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  query_dept_staff_tree_aim,
  query_role_group_info_list
} from '@api/user'
export default {
  created() {
    this.getTree()
    
     
  },
  mounted() {
    this.$nextTick(() => {
        // 
                   console.log(1)
    //  this.$refs.eltree.setCheckedKeys(this.listdata.map(x=>x.id))
    })  
  
  },
  watch: {
    checkop(newval, oldval) {
      // console.log(111);

      //   this.mun &&
      //   this.checkop.length > 1 &&
      //   this.$message.warning('代写只能选择一人') &&
      //   this.checkop.splice(1)
    },
    listdata(val){
        //  this.mun &&
        // this.listdata.length > 1 &&
        // this.$message.warning('代写只能选择一人') &&
        // this.listdata.splice(1)
    }
  },
  filters: {
    first: (val) =>
      (val = String(parseInt(val)) === 'NaN' ? val.substring(0, 1) : parseInt(val))
  },
  props: {
    mun: Boolean,
    jiaose: String,
    dialogVisible: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    shop_id: String,
    treedata: {
      type:Array,
      default:()=>[]
    },
    drtjData: {
      type: Array,
      default() {
        return []
      }
    },
    props_istdata: {
      type:Array,
      default:() => []
    }
    
  },
  data() {
    return {
      treeData: [],
      listdata:this.props_istdata,
      treeDatas: [],
      jiaoseData: [],
      activeNames: ['1'],
      active1: '',
      checkop: [],
      Isshow: '1',
      expandedData: [],
      checkedData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      ent_id:this.$store.state.ent_id
    }
  },
  computed: {
    title:function(){
      const con = ['请选择组织架构','请选择角色','请选择人员']
      const  num = Number(this.Isshow)-1
      return con[num]
    },
    jiaoseDatas:function(){
      if(this.Isshow !== '2'){
      return this.jiaoseData
      }else{
           this.treedata.forEach(item=>{
             item.role_name = item.staff_name
             item.label =  item.staff_name
            })
          return [
            {
              role_group_name:this.jiaose,
              role_list: this.treedata
            }
          ]
      }
    },
    treeData1:function(){
      return this.treeData
      //   if(this.shop_id ){
      //    let data = this.treeDatas.find(x=>{
      //  return x.id === this.shop_id
      // }) || []
      //   if(data.length === 0){
      //     return []
      //   }else{
      //      return  [data]
      //   }
         
      //   }else{
      //     return this.treeData
      //   }
    }
  },
  methods: {
    async getTree() {
      try {
      let ent_id = this.ent_id || JSON.parse(sessionStorage.getItem('qiye')).staff_info.ent_id
         console.log(ent_id,'--****----55');
        const param = {
          ent_id,
          active: '1'
        }
        const data = await query_dept_staff_tree_aim({ data: { ent_id:param.ent_id}})
        const res =  await query_role_group_info_list({ data: param })
        this.jiaoseData = res.body.data
        console.log( res.body.data);
        this.treeData = data.body.data
        this.treeDatas = data.body.data
        //如果shop_id存在
        // if(this.shop_id){
        // let mytreedata =   data.body.data.find(x=>{
        //     return   x.shop_id === this.shop_id
        //   })
        // }
        // this.checkedData = res1.data.map((item) => item.staff_id)
        // this.expandedData = res1.data.map((item) => item.staff_id)
      } catch (e) {
        console.log(e);
      }
    },
    // 数组去重
    setArray(list) {
      const List = list || []
      return Array.from(new Set(List))
    },
    del(index, item) {
      this.listdata.splice(index, 1)
      item.staff_id = item.staff_id || item.id
      this.$refs.eltree.setChecked(item.staff_id, false)
      // setChecked
      // console.log(item, this.checkedData, this.expandedData)
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
    hankld(val) {
      console.log('点击树的子节点val',val);
      this.listdata = Object.assign(
        this.listdata,
        this.$refs.eltree.getCheckedNodes().filter((item) => item.type === '2')
      )
      this.listdata = this.setArray(this.listdata)
      // this.input()
      // console.log(this.listdata);
    },
    checkbox(x, y) {
      if (this.listdata.includes(x)) {
        this.listdata = this.listdata.filter(ele => ele != x)
      } else {
        this.listdata.push(x)
      }
    },
    hankldchange(val) {
      // console.log(val,'这是hankldchange事件')
    },
    handleChange(val) {
      // console.log(val)
    },
    changeDialog() {
       //this.dialogVisible = false
      //  console.log(this.listdata);
       this.$emit('update:dialogVisible', false)
       this.$emit('changeDialog', this.listdata)
    },
    // input(){
    //     this.$emit('update:listdata',this.listdata)
    // },
    checkboxChange(val) {}
  },


  updated() {
  
     if(this.jiaose && typeof this.jiaose === 'string'){
       this.Isshow = '2'
    }else if(this.treedata && Array.from(this.treedata).length!== 0 && !this.shop_id){
      this.Isshow = '3'
    }else{
      this.Isshow = '1'
    }

  }
}
</script>

<style scoped lang="scss">
.grayBLock {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .choose-box {
    width: 45%;
    height: 354px;
    background: #e7e8eb;
    overflow: auto;
    padding-top: 4px;
    &-title {
      width: 100%;
      height: 30px;
      border-radius: 30px;
      padding-left: 2px;
      padding-right: 2px;
      display: inline-flex;
      > span {
        width: 100%;
        color: #333;
        text-align: center;
        background: #cccece;
        line-height: 30px;
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        &.active {
          background: #0089ff;
          color: #fff;
          z-index: 2;
          &:hover {
            background: #319bf8;
          }
        }
      }
    }
  }
  .right {
    width: 50%;
    height: 354px;
    background: #e7e8eb;
    display: inline-flex;
    flex-flow: column nowrap;
    &-ul {
      height: 30px;
      line-height: 30px;
      padding: 0 10px 0 10px;
      margin: 0;
      &.active {
        background-color: #f6f8f9;
      }
      &:hover {
        background-color: #f0f7ff;
      }
      > li {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        > div {
          width: 22px;
          height: 22px;
          align-self: center;
          border-radius: 25px;
          background-color: #1890ff;
          overflow: hidden;
          color: #fff;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
        > p {
          margin-left: 10px;
          flex: 1;
        }
        > .c-icon {
          align-self: center;
          font-size: 20px;
        }
      }
    }
  }
}
::v-deep .el-collapse-item {
  width: 100%;
  background: #e7e8eb;
  height: auto;
}
::v-deep .el-collapse-item__header {
  background: #e7e8eb;
  border-bottom: none;
  padding-left: 20px;
  height: 30px;
}
::v-deep .el-collapse-item__content {
  background: #e7e8eb;
  border-bottom: none;
  padding-left: 20px;
}
::v-deep .el-checkbox-group {
  display: flex;
  flex-direction: column;
}
.el-scrollbar {
  height: 100%;
}

::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  height: calc(100% + 17px);
}

//选中框样式
#checkboxclass {
  display: none;
  position: relative;
}
#checkboxclass + span {
  width: 16px;
  height: 16px;
  background-color: #fff !important;
  display: inline-block;
  overflow: auto;
  border-radius: 2px;
  box-sizing: border-box;
  &:hover{
    border: 1px solid #1890ff;
    border-radius: 2px;
  }
}
#checkboxclass-span.checked{
background: url('../../../assets/img/kuang_duigou.png');
background-size: cover;
}
.choose-box-li{
  width: 100%;
  height: 30px;
  line-height: 30px;
  list-style: none;

  > label{
      display: inline-flex;
      align-items: center;
      >p{
        margin-left: 8px;
      }
  }
}
</style>
