import axios from '@/utils/request'
/**
 * 添加审批列表分组
 *  @param { ent_id, user_id, role_group_name:分组名称 }
 */
const insert_flow_form_info = (data) => axios.post('/insert_flow_form_info', {...data })
    /**
     * /**
     * 添加审批列表分组
     *  @param { ent_id, user_id, role_group_name:分组名称 }
     */
const insert_flow_info_public = (data) => axios.post('/insert_flow_info_public', {...data })
    /**
    /**
     * 查询审批分组列表
     * @param { ent_id }
     */
const query_flow_group_info_list = (data) => axios.post('/query_flow_group_info_list', {...data })
    /**
     * 添加审批列表分组
     *  @param { ent_id, user_id, role_group_name:分组名称 }
     */
const insert_flow_group_info = (data) => axios.post('/insert_flow_group_info', {...data })
    /**
     * 删除审批列表分组
     * @param {ent_id，flow_group_ids：分组id}
     */
const delete_flow_group_info = (data) => axios.post('/delete_flow_group_info', {...data })
    /**
     * 编辑审批列表分组
     * @param {ent_id,user_id,flow_group_id,flow_group_name:分组名称}
     */
const update_flow_group_info = (data) => axios.post('/update_flow_group_info', {...data })
    /**
     * 修改单据的分组属性
     * @param {ent_id, flow_id:表单id,flow_group_id:分组id,orig_group_id:原分组id}
     */
const update_group_of_flow_form = (data) => axios.post('/update_group_of_flow_form', {...data })

/**
 * 新增审批流程--自定义表单
 * 点击编辑时将数据备份 修改时实时更新接口数据 如果点击保存数据保持现状 否则数据返回为备份的数据
 * @param { ent_id, user_id, flow_id:表单id, flow_name:表单名称, flow_desc:描述, flow_group_id:分组id,
 * manager:谁可以管理这个模板并导出数据，只能是人,
 * distinct_flag:是否去重，1同一个审批人在流程中出现多次时，仅保留第一个，2同一个审批人仅在连续出现时，自动去重，3不启用自动去重,
 * print_flag:打印模板，1默认模板，2自定义模板,
 * appr_must_flag:	审批意见是否必填：1必填，0非必填,
 * appr_tips:审批意见填写提示,
 * appr_auto:发起人审批时自动通过：1通过，0不通过,
 * form_set:表单内容设置,
 * flow_set:表单的审批流程设置,
 * logo_url:表单的logo
 * }
 */
// 公共表单 流程设计保存
const insert_flow_action_str = (data) => axios.post('/insert_flow_action_str', {...data })

//公共表单  表单数据保存
const replace_flow_form_info_private = (data) => axios.post('/replace_flow_form_info_private', {...data })

const insert_cust_form = (data) => axios.post('/insert_cust_form', {...data })
const insert_flow_info = (data) => axios.post('/insert_flow_info', {...data })
    // 自定义表单  点击编辑
const query_cust_form_detail = (data) => {
    return axios.post('/query_cust_form_detail', {...data })
}
const query_edit_list = (data) => {
        return axios.post('/query_edit_list', {...data })
    }
    // 查询表单的管理者
const query_manager_of_form = (data) => {
    return axios.post('/query_manager_of_form', {...data })
}

// 查询审批流程
const query_flow_info_all = (data) => {
    return axios.post('/query_flow_info_all', {...data })
}

/**
 * 我已审批的列表
 * @param { ent_id, user_id }
 */
const query_flow_list_have_appr_by_me = (data) => axios.post('/query_flow_list_have_appr_by_me', {...data })
    /**
     * 审批-我发起的列表
     * @param { ent_id, user_id }
     */
const query_flow_list_start_by_me = (data) => axios.post('/query_flow_list_start_by_me', {...data })
    /**
     *
     * @param {*} data
     */
const query_flow_list_copy_to_me = (data) => axios.post('/query_flow_list_copy_to_me', {...data })

/**
 *
 * @param {*} data
 */
const query_flow_list_share_or = (data) => axios.post('/query_flow_list_share_or', {...data })
    /**
     * 待我审批的
     * @param {*} data
     */
const query_flow_list_wait_me_for_appr = (data) => axios.post('/query_flow_list_wait_me_for_appr', {...data })
    // /query_appr_detail 详细信息 ent_id user_id flow_id flow_no
const query_appr_detail = (data) => axios.post('/query_appr_detail', {...data });
// /query_form_replace_write_user 查询- 发起人是否被代写及代写人 ent_id flow_no
const query_form_replace_write_user = (data) => axios.post('/query_form_replace_write_user', {...data });
// /query_appr_step_list 查询审批进程列表 ent_id flow_id flow_no
const query_appr_step_list = (data) => axios.post('/query_appr_step_list', {...data });
// /query_flow_cmt_list 查询评论l列表 ent_id flow_no
const query_flow_cmt_list = (data) => axios.post('/query_flow_cmt_list', {...data });
// /query_back_appr_step_list 查询退回的审批节点列表 ent_id flow_id flow_no node_sort
const query_back_appr_step_list = (data) => axios.post('/query_back_appr_step_list', {...data });
// /generate_pdf_form 下载详情信息 ent_id flow_id
const generate_pdf_form = (data) => axios.post('/generate_pdf_form', {...data });
/**
 *
 */
const query_form_appr_set = (data) => axios.post('/query_form_appr_set',{...data})
/**
 * 查询消息条数
 */
const query_notify_num = (data) => axios.post('/query_notify_num',{ ...data })
/**
 * 更新消息
 * @param { flow_no:'流程号', user_id:'用户id', action: 1我发起的,2待我审批,3我已审批,4抄送我的,5或签共享}
 * "161430791509546dtX"
 * "161430791509546dtX"
 * "ODyRne2kkYqqGmJ7JA7waBzxc"
 * "1614309949294FfUVv"
 */
const update_notify = (data) => axios.post('/update_notify', { ...data })
/**
 * 获取审批流程
 */
const query_form_appr_flow_list = (data) => axios.post('/query_form_appr_flow_list', { ...data })
/**
 * 审批管理排序
 */
const update_flow_group_sort = (data) => axios.post('/update_flow_group_sort', { ...data })
/**
 * 表单停用
 *  @param { ent_id, flow_id }
 */
const form_active_stop = (data) => axios.post('/form_active_stop', { ...data })
/**
 * 审批item排序
 *  @param { ent_id, list: [{ flow_id:"123",sort:3 }] }
 */
const update_flow_info_sort = (data) => axios.post('/update_flow_info_sort', { ...data })
/**
 * 发起审批申请动作
 * @param {}
 */
const start_flow_action = (data) =>axios.post('/start_flow_action', { ...data })
/**
 * 新增审批流程步骤列表
 */
const add_appr_flow_step_list = (data) =>axios.post('/add_appr_flow_step_list',{ ...data })

/**
 *
 */
const set_replace_write_user = (data) => axios.post('/set_replace_write_user',{ ...data })
// 审批   流程设计
// 角色下的人员列表
const query_user_list_of_role = (data) => {
  return axios.post('/query_user_list_of_role', {...data })
}

// 所有部门人员树
const query_dept_staff_tree_aim_not = (data) => {
  return axios.post('/query_dept_staff_tree_aim_not', {...data })
}
// 查询角色list
const query_role_info_list = (data) => {
  return axios.post('/query_role_info_list', {...data })
}
// 查询角色组list
const query_role_group_info_list = (data) => {
  return axios.post('/query_role_group_info_list', {...data })
}

const get_staff_list_like = (data) => {
  return axios.post('/get_staff_list_like', {...data })
}

// 获取部门列表
const query_ent_dept_list = (data) => {
  return axios.post('/query_ent_dept_list', {...data })
}


// 查询结构人员
const query_dept_staff_list = (data) => {
  return axios.post('/query_dept_staff_list', {...data })
}

export {
  insert_flow_form_info,
  insert_flow_info_public,
  query_flow_group_info_list,
  insert_flow_group_info,
  delete_flow_group_info,
  update_flow_group_info,
  update_group_of_flow_form,
  insert_cust_form,
  insert_flow_action_str,
  replace_flow_form_info_private,
  query_cust_form_detail,
  query_manager_of_form,
  query_flow_list_wait_me_for_appr,
  query_flow_list_have_appr_by_me,
  query_flow_list_start_by_me,
  query_appr_detail,
  query_form_replace_write_user,
  query_appr_step_list,
  query_flow_cmt_list,
  query_back_appr_step_list,
  query_form_appr_set,
  generate_pdf_form,
  query_flow_list_copy_to_me,
  query_flow_list_share_or,
  query_flow_info_all,
  query_notify_num,
  update_notify,
  query_form_appr_flow_list,
  update_flow_group_sort,
  form_active_stop,
  update_flow_info_sort,
  insert_flow_info,
  start_flow_action,
  add_appr_flow_step_list,
  query_edit_list,
  set_replace_write_user,
  query_user_list_of_role,
  query_dept_staff_tree_aim_not,
  query_role_info_list,
  query_role_group_info_list,
  get_staff_list_like,
  query_ent_dept_list,
  query_dept_staff_list
}
