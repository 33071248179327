<template>
  <div class="menu">
    <el-tree
      v-show="renderComponent"
      :data="dataList"
      style="background: none"
      show-checkbox
      ref="cat_ref"
      highlight-current
      node-key="cat_id"
      :default-expanded-keys="[]"
      :default-checked-keys="checkedCities"
      @check="hankld"
      :props="defaultProps"
    >
    </el-tree>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState(["ent_id"]),
    options_caipin() {
      return this.$store.state.cat_list;
    },
  },
  data() {
    return {
      renderComponent: false,
      dataList: [],
      checkedCities: ["CP161763627105391bn", "CP1617636321154k6fz"],
      default_cat_list: [],
      defaultProps: {
        children: "prod_children",
        label: "cat_name",
      },
    };
  },
  created() {
    this.dataList = this.options_caipin;
    for (const i of this.dataList) {
      for (const j of i.prod_children) {
        j.cat_name = j.prod_name;
        j.cat_id = j.prod_id;
      }
    }
  },
  mounted() {
    this.checkedCities = this.value;
    this.renderComponent = false;

    this.$nextTick().then(() => {
      this.renderComponent = true;
    });
  },
  watch: {
    value() {
      this.checkedCities = this.value;
      this.$nextTick(() => {
        this.$refs.cat_ref.setCheckedKeys(this.checkedCities);
      });
    },
  },
  methods: {
    hankld() {
      this.checkedCities = this.$refs.cat_ref
        .getCheckedNodes()
        .filter((item) => item.parent_id !== "0")
        .map((x) => x.cat_id);
      // this.input()
    },
    // my_change(val) {
    //     if(val.checked){
    //         for (const i of val.prod_children){
    //             this.checkedCities.push(i.prod_id)
    //         }
    //          this.checkedCities = [...new Set(this.checkedCities)]
    //     }else{
    //          this.checkedCities = [...new Set(this.checkedCities)]
    //          for (const i of val.prod_children){
    //             let index =  this.checkedCities.indexOf(i.prod_id)
    //             this.checkedCities.splice(index,1)
    //         }
    //     }
    // },
    input() {
      this.$emit("options_value", this.checkedCities);
      this.$emit("input", this.checkedCities);
    },
    handleCheckedCitiesChange(val) {
      //  this.$emit('options_value',this.checkedCities)
      // console.log(this.checkedCities);
      // let checkedCount = value.length;
      // this.checkAll = checkedCount === this.cities.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount <script this.cities.length;
    },
  },
  updated() {
    this.input();
  },
};
</script>

<style scoped lang="scss">
.menu {
  min-width: 300px;
}

/deep/ .el-checkbox-group {
  display: flex;
  flex-flow: column nowrap;
}
/deep/ .el-collapse-item__header {
  height: 24px;
}
</style>