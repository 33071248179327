// 封装axios的请求，返回重新封装的数据格式
// 对错误的统一处理
import axios from 'axios'
import errorHandle from './errorHandle'
// import store from '@/store'
import publicConfig from '@/config'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const CancelToken = axios.CancelToken
class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
        this.pending = {}
    }

    // 获取axios配置
    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            timeout: 10000
        }
        return config
    }



    // 设定拦截器
    interceptors(instance) {
        // 请求拦截器
        instance.interceptors.request.use(
            (config) => {
                // if(config.url.indexOf('file_upload_ming') > -1) {
                //     console.log('zou------', config.data)
                //     console.log('config----', config.data.get('files'))
                // }
                // Do something before request is sent
                // let isPublic = false

                // publicConfig.publicPath.map((path) => {
                //         isPublic = isPublic || path.test(config.url)
                //     })
                // const token = store.state.token
                // if (!isPublic && token) {
                //   config.headers.Authorization = 'Bearer ' + token
                // }
                const key = config.url + '&' + config.method

                config.cancelToken = new CancelToken((c) => {
                    this.pending[key] = c
                })
                return config
            },
            (err) => {

                errorHandle(err)
                    // Do something with request error
                return Promise.reject(err)
            }
        )

        // 响应请求的拦截器
        instance.interceptors.response.use(
            (res) => {
              
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                const key = res.config.url + '&' + res.config.method

                if (res.status === 200) {
                     
                    if (res.data.code === '500') {
                        return Promise.resolve(res.data)
                    }
                    if(res.data.body){
                        const obj = res.data.body
                        for (const i in obj) {
                            obj[i] = typeof obj[i] === 'string' && JSON.parse(obj[i]) || obj[i]
                        }
                        res.data.body = obj
                        return Promise.resolve(res.data)
                    }else{
                        return Promise.resolve(res.data)
                    }

                 
                  
                } else {

                    return Promise.reject(res)
                }
            },
            (err) => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error

                errorHandle(err)
                return Promise.reject(err)
            }
        )
    }

    // 创建实例
    request(options) {
        const instance = axios.create()
        const newOptions = {...this.getInsideConfig(), ...options}
        this.interceptors(instance)
        return instance(newOptions)
    }

    get(url, config) {
        const options = Object.assign({
                method: 'get',
                url: url
            },
            config
        )
        return this.request(options)
    }

    post(url, data, opt) {
        return this.request({
            method: 'post',
            url: url,
            data: data,
            ...opt
        })
    }
}

export default HttpRequest