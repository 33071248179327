import { query_shop_info_list } from '@api/store'
import { query_ent_staff_list } from '@api/foodqiye'
import { query_prod_tree_of_1cat_2prod } from "@api/foodqiye"
class PublicInfo {
  constructor (){

  }

  // 通过ent_id获取菜品
  async getShopFoodsList(params) {
    // let result = ''
    let data = {data:params}
    let res = await query_prod_tree_of_1cat_2prod(data)
    return res
  }

  async get_shop_list(ent_id){ //获取门店信息
    let result = ''
    const data = await query_shop_info_list({data:{ent_id}})
    if(data.code === '200'){
      result = data.body.data.map(x=>{
        return {
          shop_name:x.shop_name,
          shop_id:x.shop_id
        }
      })
    }
    return result
  }

  

  async query_ent_staff_list(ent_id){ //获取企业下面所有人
      let result = ''
      const data = await query_ent_staff_list({data:{ent_id}})
      if(data.code === '10149'){
        result = data.body.data.map(x=>{
          return {
            staff_id:x.staff_id,
            name:x.name
          }
        })
      }
      // if(data.code === '200'){
      //   result = data.body.data
      // }
      return result
  }
 //获取菜单二级
  async query_prod_tree_of_1cat_2prod(ent_id){
      let result = ''
      const data = await query_prod_tree_of_1cat_2prod({data:{ent_id}})
      if(data.code === '200'){
        result = data.body.data
      }
      return result 
  } 
}
export default new PublicInfo()
