<template>
  <div v-show="isShow">
    <div class="alert">
      <div class="flex">{{ msg }}</div>
      <div v-if="type === 'alert'">
        <div class="btnCommon success" @click="close()">确定</div>
      </div>
      <div v-else class="space-round">
        <div class="btnCommon cancel" @click="cancelEvent()">取消</div>
        <div class="btnCommon success" @click="successEvent()">确定</div>
      </div>
    </div>
    <div class="mask" @click="closeMask()"></div>
  </div>
</template>
<script>
// import { query_mc_pay_rate } from '@api/user'
export default {
  props: {
    type: {
      type: String,
      default: 'alert'
    },
    msg: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    },
    success: {
      type: Function,
      default: () => {
        console.log('点击了success')
      }
    },
    cancel: {
      type: Function,
      default: () => {
        console.log('点击了cancel')
      }
    }
  },
  mounted() {
    // const param = { ent_id: 'QY1580619678461k6gx' }
    // query_mc_pay_rate({ data: param }).then((res) => {
    //   console.error(res)
    // })
  },
  methods: {
    close() {
      this.isShow = false
    },
    closeMask() {
      if (this.type === 'alert') {
        this.close()
      }
      this.close()
    },
    cancelEvent() {
      this.cancel()
      this.close()
    },
    successEvent() {
      this.success()
      this.close()
    }
  }
}
</script>
<style lang="scss" scoped>
$btn-main: #009688;
$btn-dartk: darken($btn-main, 5%);
.alert {
  width: 300px;
  height: 150px;
  position: fixed;
  background: #fff;
  border-radius: 6px;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -75px;
  padding: 20px;
  box-shadow: 0 5px 8px rgba($color: #000000, $alpha: 0.04);
  z-index: 3000;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.flex {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.btnCommon {
  width: 105px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 6px;
  &.success {
    background: #009688;
    color: #fff;
    &:hover {
      background: $btn-dartk;
    }
  }
  &.cancel {
    background: #ededed;
    color: #333;
  }
}
.space-round {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 2000;
}
</style>
