import Vue from 'vue'
Vue.prototype.$bendi_cunfang_cunchu = function(name, cunchu, type) {
  if (type === '暂时') {
    return sessionStorage.setItem(name, JSON.stringify(cunchu))
  }
  if (type === '永久') {
    return localStorage.setItem(name, JSON.stringify(cunchu))
  }
}
Vue.prototype.$bendi_naqu_cunchu = function(name, type) {
  if (type === '暂时') {
    if (sessionStorage.getItem(name) !== null && sessionStorage.getItem(name) !== undefined && sessionStorage.getItem(name) !== '') {
      // console.log( JSON.parse( sessionStorage.getItem(name)) ,   '暂时暂时暂时暂时暂时暂时暂时', JSON.parse(JSON.stringify( sessionStorage.getItem(name))) )
      // return JSON.parse(JSON.stringify( sessionStorage.getItem(name))) 不可以会出错
      return JSON.parse(sessionStorage.getItem(name)) // 没有修改的
    }
  }
  if (type === '永久') {
    if (localStorage.getItem(name) !== null && localStorage.getItem(name) !== undefined && localStorage.getItem(name) !== '') {
      return JSON.parse(localStorage.getItem(name))
    }
  }
}

// 流程 审批设计
import nodeWrap from '@c/setting/nodeWrap'
import addNode from '@c/setting/addNode'
Vue.use(nodeWrap)
Vue.component('nodeWrap', nodeWrap)
Vue.use(addNode)
Vue.component('addNode', addNode)
Vue.directive('enterNumber', {
    bind: function(el, { value = 2 }) {
        el = el.nodeName == 'INPUT' ? el : el.children[0]
        var RegStr = value == 0 ? '^[\\+\\-]?\\d+\\d{0,0}' : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`
        el.addEventListener('keyup', function() {
            el.value = el.value.match(new RegExp(RegStr, 'g'))
            el.dispatchEvent(new Event('input'))
        })
    }
})
Vue.directive('focus', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function(el) {
        el.focus()
    }
})

//拖拽
const drag = Vue.directive('drag', {
  // 1.指令绑定到元素上回立刻执行bind函数，只执行一次
  // 2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
  // 3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
  bind: function(el) {},
  // inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
  inserted: function(el) {
    el.onmousedown = function(e) {
      var disx = e.pageX - el.offsetLeft
      var disy = e.pageY - el.offsetTop
      document.onmousemove = function(e) {
        el.style.left = e.pageX - disx + 'px'
        el.style.top = e.pageY - disy + 'px'
      }
      document.onmouseup = function() {
        document.onmousemove = document.onmouseup = null
      }
    }
  },
  // 当VNode更新的时候会执行updated，可以触发多次
  updated: function(el) {}
})
window.drag = new Vue()


