<template>
    <button class="c-button" :class="{[`icon-${iconPosition}`]:true}" @click="$emit('click')">
     <c-icon v-if="icon && !loading" class="icon" :name="icon"></c-icon>
     <c-icon  class="loading icon" v-if="loading" :name="'loading'"></c-icon>
      <div class="content">
        <slot></slot>
      </div>
    </button>
</template>

<script>
export default {
  props: {
    icon: {},
    loading: {
      type: Boolean,
      default: false
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator(value) {
        return value === 'left' || value === 'right'
      }
    }
  }
}
</script>
<style lang="scss">
@keyframes spin{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
    .c-button {
        font-size: var(--font-size);
        height: var(--button-height);
        padding: 0 1em;
        border-radius: var(--border-radius);
        border:1px solid var(--border-color);
        background:var(--button-bg);
        color:var(--color);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align:middle;
        &:hover {
            border-color:var(--border-color-hover);
        }
        &:active {
            background-color: var(--button-active-bg);
        }
        &:focus {
            outline:none;
        }
        > .content{
          order:2;
        }
        > .icon {
          order:1;
          margin-right: .1em;
        }
        &.icon-right{
          > .content{
            order: 1;
          }
          > .icon{
            order: 2;
            margin-right: 0;
            margin-left: .1em;
          }
        }
        .loading{
          animation: spin 1s infinite linear;
        //  fill:red;
        }
    }
</style>
