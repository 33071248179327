import Vue from 'vue'
import Button from '@c/modules/button/button'
import Icon from '@c/modules/icon'
import ButtonGroup from '@c/modules/buttonGroup'
import Ctree from '@c/modules/Tree'
import Menu from '@c/modules/menu'
import menuList from '@c/modules/menu-list'

Vue.component('c-button', Button)
Vue.component('c-icon', Icon)
Vue.component('c-tree', Ctree)
Vue.component('c-button-group', ButtonGroup)
Vue.component('c-menu',Menu)
Vue.component('c-menuList',menuList)



