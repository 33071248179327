import axios from '@/utils/request'

// /ent_acct_login // 企业账号登陆
const ent_acct_login = (data) => {
    return axios.post('/ent_acct_login', {...data })
}
const query_ent_staffNum_registerNum = (data) => {
    return axios.post('/query_ent_staffNum_registerNum', {...data })
}



// /pwd_login 用户密码登录
const pwd_login = (data) => {
        return axios.post('/pwd_login', {...data })
    }
    // alter_ent_acct_pwd  //修改企业账号密码
const alter_ent_acct_pwd = (data) => {
    return axios.post('/alter_ent_acct_pwd', {...data })
}


// /query_user_ent_list // 获取用户相关的企业列表
const query_user_ent_list = (data) => {
    return axios.post('/query_user_ent_list', {...data })
}

// /query_user_aut_ent_list //获取用户相关的企业认证列表
const query_user_aut_ent_list = (data) => {
    return axios.post('/query_user_aut_ent_list', {...data })
}

// /query_user_aut_ent //查询用户认证的企业信息-单个
const query_user_aut_ent = (data) => {
    return axios.post('/query_user_aut_ent', {...data })
}

// /get_my_info 获取当前登陆用户的企业信息
const get_my_info = (data) => {
    return axios.post('/get_my_info', {...data })
}

// /query_ent_primary_manager // 查询主管理员
const query_ent_primary_manager = (data) => {
    return axios.post('/query_ent_primary_manager', {...data })
}

// /set_ent_primary_manager // 设置主管理员
const set_ent_primary_manager = (data) => {
    return axios.post('/set_ent_primary_manager', {...data })
}

// /update_ent_primary_manager // 修改主管理员
const update_ent_primary_manager = (data) => {
    return axios.post('/update_ent_primary_manager', {...data })
}

// /query_ent_sub_manager_list // 查询子管理员列表
const query_ent_sub_manager_list = (data) => {
    return axios.post('/query_ent_sub_manager_list', {...data })
}

// /query_ent_sub_manager_list_by_module // 查询子管理员列表 模块--多个子管理员
const query_ent_sub_manager_list_by_module = (data) => {
        return axios.post('/query_ent_sub_manager_list_by_module', {...data })
    }
    // /set_ent_sub_manager// 设置子管理员 --批量
const set_ent_sub_manager = (data) => {
    return axios.post('/set_ent_sub_manager', {...data })
}

// /get_dept_list // 获取部门列表
const get_dept_list = (data) => {
    return axios.post('/get_dept_list', {...data })
}

// /query_all_staff_list_of_dept  // 查询部门及多级子部门下的所有人员列表
const query_all_staff_list_of_dept = (data) => {
    return axios.post('/query_all_staff_list_of_dept', {...data })
}

// /query_dept_staff_tree_aim_not //组织结构树，既有部门，又有人员 tree,不带目标
const query_dept_staff_tree_aim_not = (data) => {
    return axios.post('/query_dept_staff_tree_aim_not', {...data })
}

// /query_user_info // 用户身份信息：主管理员，子管理员，主管等
const query_user_info = (data) => {
    return axios.post('/query_user_info', {...data })
}

// /query_all_module_read_power_detail // 全模块下的权限明细
const query_all_module_read_power_detail = (data) => {
    return axios.post('/query_all_module_read_power_detail', {...data })
}

// /delete_ent_sub_manager // 删除子管理员 --批量
const delete_ent_sub_manager = (data) => {
    return axios.post('/delete_ent_sub_manager', {...data })
}

// hwb_client
const hwb_client = (data) => {
    return axios.post('/hwb_client', {...data })
}

// -----     -----     -----     -----    -----     -----     -----
// /insert_prod_info  // 新增产品  新增菜品
const insert_prod_info = (data) => {
    return axios.post('/insert_prod_info', {...data })
}

// /query_prod_info_list // 查询产品列表
const query_prod_info_list = (data) => {
    return axios.post('/query_prod_info_list', {...data })
}

// /query_category_info_list // 查询产品种类列表
const query_category_info_list = (data) => {
    return axios.post('/query_category_info_list', {...data })
}

// /insert_category_info // 获取产品列表 菜品列表
const insert_category_info = (data) => {
    return axios.post('/insert_category_info', {...data })
}

// /get_prod_list  // 获取产品列表
const get_prod_list = (data) => {
    return axios.post('/get_prod_list', {...data })
}

// /delete_prod_info // 删除产品--批量删除
const delete_prod_info = (data) => {
    return axios.post('/delete_prod_info', {...data })
}

// /import_customer_ai // / 导入客户信息--智能导入--多部门 文件导入
const import_customer_ai = (data) => {
    return axios.post('/import_customer_ai', {...data })
}

// /update_prod_info // 修改产品 参数同insert_prod_info
const update_prod_info = (data) => {
        return axios.post('/update_prod_info', {...data })
    }
    // /insert_cost_info // 新增成本卡
const insert_cost_info = (data) => {
        return axios.post('/insert_cost_info', {...data })
    }
    // /query_cost_info_detail // 查询成本卡明细
const query_cost_info_detail = (data) => {
    return axios.post('/query_cost_info_detail', {...data })
}

// /query_prod_info_detail  // 查询产品明细
const query_prod_info_detail = (data) => {
  return axios.post('/query_prod_info_detail', {...data })
}

// /get_prod_list_like // 获取产品列表 --模糊查询
const get_prod_list_like = (data) => {
    return axios.post('/get_prod_list_like', {...data })
}

// /query_combo_prod_list // 查询套餐的产品列表
const query_combo_prod_list = (data) => {
    return axios.post('/query_combo_prod_list', {...data })
}

// /query_combo_cost_list // 查询套餐的成本列表
const query_combo_cost_list = (data) => {
  return axios.post('/query_combo_cost_list', {...data })
}
// /update_cost_info // 更新成本卡
const update_cost_info = (data) => {
  return axios.post('/update_cost_info', {...data })
}

// /query_all_prod_list_of_category  // 查询产品类型下的所有产品list
const query_all_prod_list_of_category = (data) => {
  return axios.post('/query_all_prod_list_of_category', {...data })
}

// ------      ------      ------      ------
// /insert_store_house // 新增仓库
const insert_store_house = (data) => {
    return axios.post('/insert_store_house', {...data })
}

// /query_store_house_list  // 查询仓库列表
const query_store_house_list = (data) => {
    return axios.post('/query_store_house_list', {...data })
}

// /query_stop_store_house_list // 查询停用仓库列表
const query_stop_store_house_list = (data) => {
    return axios.post('/query_stop_store_house_list', {...data })
}

// /store_house_active_start  // 启用仓库--批量
const store_house_active_start = (data) => {
    return axios.post('/store_house_active_start', {...data })
}

// /store_house_active_stop // 停用仓库--批量
const query_dept_staff_list = (data) => {
    return axios.post('/query_dept_staff_list', {...data })
}

// /store_house_active_stop // 停用仓库--批量
const store_house_active_stop = (data) => {
    return axios.post('/store_house_active_stop', {...data })
}

// /update_store_house  // 修改仓库
const update_store_house = (data) => {
    return axios.post('/update_store_house', {...data })
}

// /delete_store_house  // 删除仓库--批量删除
const delete_store_house = (data) => {
    return axios.post('/delete_store_house', {...data })
}

// /query_ent_site_tree  // 查询仓库--树状图
const query_ent_site_tree = (data) => {
    return axios.post('/query_ent_site_tree', {...data })
}
// 仓库树：query_site_tree
const query_site_tree = (data) => {
  return axios.post('/query_site_tree', {...data })
}

// /query_stock_mng_detail_site_list //库存管理--总账，明细记账中的仓库列表
const query_stock_mng_detail_site_list = (data) => {
    return axios.post('/query_stock_mng_detail_site_list', {...data })
}

// /query_prod_of_store_house_list  // 查询仓库下的产品列表
const query_prod_of_store_house_list = (data) => {
    return axios.post('/query_prod_of_store_house_list', {...data })
}

// /batch_update_prod_store // 批量修改产品的仓库属性
const batch_update_prod_store = (data) => {
    return axios.post('/batch_update_prod_store', {...data })
}

const query_user_auth = (data) => {
  return axios.post('/query_user_auth', {...data })
}


/**
 *  // 新增部门
 * @param { ent_id, user_id, dept_id:新增无，更新传部门id	,dept_name:部门名字,parent_id：父id,child_flag：是否有子部门：1有，0没有,dept_leader_id：	部门负责人id,
 * aim_prop：是否有目标：1有，0没有} data
 * @returns
 */
const set_ent_dept_info = (data) => {
    return axios.post('/set_ent_dept_info', {...data })
}
const query_ent_dept_list_of_all = (data) => {
    return axios.post('/query_ent_dept_list_of_all', {...data })
}
const query_ent_staff_list = (data) => {
        return axios.post('/query_ent_staff_list', {...data })
    }
    /**
     * 批量修改部门启用停用状态
     * @param {ent_id，user_id，active，dept_ids} data
     * @returns {
        "code": "200",
        "msg": "MSG_CODE_SUCCESS",
        "body": null
    }
     */
const update_dept_active_batch = (data) => axios.post('/update_dept_active_batch', {...data })
    /**
     * 停用部门list
     * @param { active: "0", ent_id:, user_id:}
     */
const query_ent_dept_list2 = (data) => axios.post('/query_ent_dept_list2', {...data })
    /**
     * 更改部门childer——falg
     *   @param {}
     */
const update_dept_child_flag = (data) => axios.post('/update_dept_child_flag', {...data })
    /**
     *  查询早午晚市划分菜品列表
     * @param {ent_id;shop_id;market_type:早市} data
     */
const query_as_market_prod_list = (data) => axios.post('/query_as_market_prod_list', {...data })
    /**
     * 保存餐品规则
     *  @param { }
     *
     */
const insert_as_market_prod = (data) => axios.post('/insert_as_market_prod', {...data })
    /**
     * 获取店铺售卖规则
     * @param {*} data ent_id
     */
const query_as_prod_rule = (data) => axios.post('/query_as_prod_rule', {...data })
    /**
     * 获取店铺售卖规则
     * @param {*} data ent_id
     */
const update_dept_sort = (data) => axios.post('/update_dept_sort', {...data })
const query_dept_tree = (data) => axios.post('/query_dept_tree', {...data })
/**
 * 菜品二级
 * @param {*} data 
 */
const query_prod_tree_of_1cat_2prod = (data) => axios.post('/query_prod_tree_of_1cat_2prod', {...data})

export {
    update_dept_sort,
    query_dept_tree,
    query_ent_staff_list,
    ent_acct_login,
    pwd_login,
    query_user_ent_list,
    query_user_aut_ent_list,
    query_user_aut_ent,
    get_my_info,
    query_ent_primary_manager,
    set_ent_primary_manager,
    update_ent_primary_manager,
    query_ent_sub_manager_list,
    query_ent_sub_manager_list_by_module,
    set_ent_sub_manager,
    get_dept_list,
    query_all_staff_list_of_dept,
    query_dept_staff_tree_aim_not,
    query_user_info,
    query_all_module_read_power_detail,
    delete_ent_sub_manager,
    hwb_client,
    query_dept_staff_list,
    // -----     -----   ------
    insert_prod_info,
    query_prod_info_list,
    query_category_info_list,
    insert_category_info,
    get_prod_list,
    delete_prod_info,
    import_customer_ai,
    update_prod_info,
    insert_cost_info,
    query_cost_info_detail,
    get_prod_list_like,
    query_combo_prod_list,
    query_combo_cost_list,//查询套餐的成本列表
    query_site_tree,
    query_prod_info_detail,  // 查询产品明细
    update_cost_info,  // 更新从成本卡
    query_all_prod_list_of_category, // 查询产品类型下的所有产品list
    // ------      ------      ------
    insert_store_house,
    query_store_house_list,
    query_stop_store_house_list,
    store_house_active_start,
    store_house_active_stop,
    update_store_house,
    delete_store_house,
    query_prod_of_store_house_list,
    batch_update_prod_store,
    set_ent_dept_info,
    update_dept_active_batch,
    query_ent_dept_list2,
    update_dept_child_flag,
    query_ent_dept_list_of_all,
    query_ent_staffNum_registerNum,
    query_as_market_prod_list,
    insert_as_market_prod,
    query_as_prod_rule,
    query_prod_tree_of_1cat_2prod,
    // ------
    query_user_auth,
}