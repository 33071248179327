<template>
  <div class="menu_list">
   <!-- <ul>
       <li v-for="(item,index) in options_caipin" :key="index">{{item.cat_name}}</li>
   </ul> -->

   <el-collapse accordion>
        <el-collapse-item v-for="(item,index) in options_caipin" :key="index" v-show="item.prod_children.length > 0">
            <template slot="title" >
                {{item.cat_name}}
            </template>
                 <ul v-for="(ite,inde) in item.prod_children" :key="inde" >
                     <li v-show="value.indexOf(ite.prod_id) !== -1" class="main-ul">{{ite.prod_name}} <c-icon name="guanbi3" @click.native="del(ite)"></c-icon></li> 
                 </ul>
        </el-collapse-item>
    
        </el-collapse>
  </div>
</template>

<script>

import { mapState,mapActions} from 'vuex'
export default {
  props: {
      value:{
          type: Array,
          default:()=>[]
      }
  },
  computed: {
        ...mapState(['ent_id']),
    //    cat_list
    options_caipin(){
        let data = this.$store.state.cat_list
        let arr = []
        data.forEach(e => {
          let data =  this.value.some(y=>{
                 return e.prod_children.find(x=>x.prod_id === y)
            })
           if(data){
               arr.push(e)
           } 
        })
        return arr
    }
    },
  data() {
    return {
    
    }
  },
  created() {
   // this.query_prod_tree_of_1cat_2prod()
  },
  methods: {
    del(val){
       let index =  this.value.indexOf(val.prod_id)
       this.value.splice(index,1)
    },
    input(){
          this.$emit('input',this.value)
     },
    // query_prod_tree_of_1cat_2prod() {
    //   query_prod_tree_of_1cat_2prod({ data: { ent_id: this.ent_id } }).then(
    //     (res) => {
    //       if (res.code === "200") {
    //         this.options_caipin = res.body.data
    //         console.log( this.options_caipin)
    //       }
    //     }
    //   )
    // },
    handleCheckedCitiesChange(value) {
        //  this.$emit('options_value',this.checkedCities)
       // console.log(this.checkedCities);
    // let checkedCount = value.length;
    // this.checkAll = checkedCount === this.cities.length;
    // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    }
  },
  updated(){
     this.input()
  }
}
</script>

<style scoped lang="scss">
.menu_list{
     max-width:300px;
     .main-ul{
        display:flex;
        justify-content: space-between;
        padding-right: 20px;
       align-items: center;
       .c-icon{
           fill: rgb(155, 155, 155);
        &:hover{
            fill:#333;
        }   
       }
        
     }
}
   
/deep/ .el-checkbox-group{
    display: flex;
    flex-flow: column nowrap;
}
/deep/ .el-collapse-item__header{
    height: 24px;
}
</style>